import axios from 'axios';

const appmode = process.env.REACT_APP_MODE; 
const API_URL = appmode === 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL;

export const SharedHostingPlanes = async () => {
  try {
    const response = await axios.get(`${API_URL}cloud/gethostingplans`);
    console.log('API Plans Response:', response); // Log the response
    return response;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const GetRegion = async () => {
  try {
    const response = await axios.get(`${API_URL}cloud/getregion`);
    console.log('API Plans Response:', response); // Log the response
    return response;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const CloudVpsPlanes = async () => {
  try {
    const response = await axios.get(`${API_URL}cloud/getvpsplans`);
    console.log('API Plans Response:', response); // Log the response
    return response;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};