import React, { useEffect, useState } from 'react';

import { PricingPlansContent, PricingPlansElement } from '../../services/apiService';

const PricingPlans = () => {
    const [featureContent, setFeatureContent] = useState([]);
    const [featureElements, setFeatureElements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                console.log('Fetching feature content and elements...');
                const content = await PricingPlansContent();
                const elements = await PricingPlansElement();

                console.log('Feature content:', content);
                console.log('Feature elements:', elements);

                setFeatureContent(content); // Assuming content is an array
                setFeatureElements(elements); // Assuming elements is an array
            } catch (error) {
                console.error('Failed to load data:', error);
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, []);

    

    // Extract the first item from featureContent array
    const [firstContent] = featureContent;
    const {
        section_value: {
            heading = '',
            description = '',
            sub_heading = '',
            plan_breadcrumb_image = ''
        } = {}
    } = firstContent || {};

    console.log('Rendering feature section with content:', firstContent);
    console.log('Rendering feature section with elements:', featureElements);

    return (
        <section className="pricing-plans pt-100 pb-100" id="pricing">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="text-center section-header section-header-two align-items-center">
                            <span className="sub-title">{sub_heading}</span>
                            <h3 className="section-title">{heading}</h3>
                            <p className="title-description">
                                {description}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row g-4 align-items-center">
                    {featureElements.map((element) => (
                        <div className="col-lg-4 col-md-6" key={element.id}>
                            <div className="pricing-item">
                                <div className="pricing-item-top">
                                    <div className="pricing-detail">
                                        <h5>
                                            <span>
                                                <svg viewBox="0 0 24 24">
                                                    <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z"></path>
                                                </svg>
                                            </span>
                                            {element.name}
                                        </h5>
                                    </div>
                                    <p>{element.description}</p>
                                </div>

                                <div className="price">
                                    <span>${element.amount} </span> <small>/ {element.duration} Days</small>
                                </div>

                                <div className="pricing-item-bottom">
                                    <ul className="pricing-features">
                                        {element.carry_forward === "1" && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                <b>Credit carry forward when renewed</b>
                                            </li>
                                        )}
                                        {element.type === "1" && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                Use Admin's Gateway for: SMS Android Email
                                            </li>
                                        )}
                                        {JSON.parse(element.sms).android.is_allowed && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                Add {JSON.parse(element.sms).gateway_limit} Android Gateways
                                            </li>
                                        )}
                                        {JSON.parse(element.whatsapp).is_allowed && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                Add {JSON.parse(element.whatsapp).gateway_limit} Whatsapp gateways
                                            </li>
                                        )}
                                        {JSON.parse(element.sms).is_allowed && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                {JSON.parse(element.sms).credits} SMS Credit
                                            </li>
                                        )}
                                        {JSON.parse(element.email).is_allowed && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                {JSON.parse(element.email).credits} Email Credit
                                            </li>
                                        )}
                                        {JSON.parse(element.whatsapp).is_allowed && (
                                            <li className="pricing-feature">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520">
                                                        <path d="M239.987 460.841a10 10 0 0 1-7.343-3.213L34.657 243.463A10 10 0 0 1 42 226.675h95.3a10.006 10.006 0 0 1 7.548 3.439l66.168 76.124c7.151-15.286 20.994-40.738 45.286-71.752 35.912-45.85 102.71-113.281 216.994-174.153a10 10 0 0 1 10.85 16.712c-.436.341-44.5 35.041-95.212 98.6-46.672 58.49-108.714 154.13-139.243 277.6a10 10 0 0 1-9.707 7.6z"></path>
                                                    </svg>
                                                </span>
                                                {JSON.parse(element.whatsapp).credits} WhatsApp Credit
                                            </li>
                                        )}
                                    </ul>
                                    <a href="http://localhost/getoneclick/user/plans" className="ig-btn btn--primary btn--lg w-100">
                                                                    Purchase Now
                                                            </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PricingPlans;
