import React, { useState, useEffect } from 'react';
import HeroSection from '../common/HeroSection/HeroSection'; 
import Clients from '../common/Clients/Clients';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import AboutSection from '../common/AboutSection';
import ProcessSection from '../common/ProcessSection';
import ChooseUsSection from '../common/ChooseUsSection';
import OverviewSection from '../common/OverviewSection';
import TeamSection from '../common/TeamSection';
import FAQSection from '../common/FAQSection';
import PricingPlans from '../common/PricingPlans';
import FeatureSection from '../common/FeatureSection';
import CloudStoragenew from '../common/SocketComponent';
import { SocketProvider } from '../../context/SocketContext';
import DynamicSEO from '../layouts/DynamicSEO';
import { general_settings, getImageBaseUrl } from '../../services/apiService';

const HomePage = () => {
  const [featureContent, setFeatureContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageBaseUrl, setImageBaseUrl] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const content = await general_settings();
        console.log('API Response:', content); // Log the response
        setFeatureContent(content[0] || {});
        setImageBaseUrl(getImageBaseUrl());
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Construct the favicon URL only if featureContent.favicon is available
  const faviconURL = featureContent.favicon
    ? `${imageBaseUrl}assets/file/images/logoIcon/${featureContent.favicon}`
    : null;
  
  console.log('Favicon URL:', faviconURL);

  return (
    <div>
      {/* Only render DynamicSEO when faviconURL is not null */}
      {faviconURL && (
        <DynamicSEO
          title="OneKlicks - Cloud Services"
          description="OneKlicks offers the best cloud services for businesses of all sizes."
          keywords="cloud hosting, cloud services, cloud solutions"
          favicon={faviconURL}
          ogTitle="OneKlicks Cloud Services"
          ogDescription="Leading provider of cloud services and hosting solutions."
          ogImage="/images/cloud-service.png"
          twitterCard="summary_large_image"
        />
      )}
      <HeroSection />
      <SocketProvider>
        {/* <CloudStoragenew /> */}
      </SocketProvider>
      <Clients />
      <AboutSection />
      <ProcessSection />
      <ChooseUsSection />
      <FeatureSection />
      <OverviewSection />
      <TeamSection />
      <FAQSection />
      <PricingPlans />
    </div>
  );
};

export default HomePage;
