import React, { useEffect, useState } from 'react';

import { AboutSectionContent, AboutSectionElement, getImageBaseUrl } from '../../services/apiService';


const AboutSection = () => {


  const [featureContent, setFeatureContent] = useState([]);
  const [featureElements, setFeatureElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl



  useEffect(() => {
    setImageBaseUrl(getImageBaseUrl());
    const loadData = async () => {
      try {
        console.log('Fetching feature content and elements...');
        const content = await AboutSectionContent();
        const elements = await AboutSectionElement();

        console.log('Feature content:', content);
        console.log('Feature elements:', elements);

        setFeatureContent(content); // Assuming content is an array
        setFeatureElements(elements); // Assuming elements is an array
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    console.log('Loading data...');
    
  }

  if (error) {
    console.error('Error encountered:', error);
  }

  if (!featureContent.length || !featureElements.length) {
    console.warn('No data available');
  }

  // Extract the first item from featureContent array
  const [firstContent] = featureContent;
  const {
    section_value: {
      title = '',
      heading = '',
      description = '',
      sub_heading = '',
      btn_name = '',
      background_image = '',
      about_image = '',
      btn_url = '',
      about_page_background_image = '',
      about_breadcrumb_image = ''
    } = {}
  } = firstContent || {};

  console.log('Rendering feature section with content:', firstContent);
  console.log('Rendering feature section with elements:', featureElements);



  return (
    <section className="grow pt-100 pb-100" id="about">
      <div className="container">
        <div className="section-header">
          <div className="section-header-left">
            <span className="sub-title">{sub_heading}</span>
            <h3 className="section-title">{heading}</h3>
          </div>

          <div className="section-header-right">
            <p className="title-description">{description}</p>
          </div>
        </div>
      </div>

      <div className="grow-container">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-6 col-lg-8">
              <div className="grow-left mt-5">
                <div className="row g-4">
                  {featureElements.map((element, index) => {
                    const {
                      section_value: {
                        title = '',
                        sub_title = '',
                        icon = ''
                      } = {}
                    } = element;

                    return (
                      <div className="col-md-6 mb-5" key={index}>
                        <div className="grow-card">
                          {/* <span className="icon-avaters">
                            <i className={getArrayValue(element.section_value, 'icon')}></i>
                          </span> */}
                          <span className="icon-avaters" dangerouslySetInnerHTML={{ __html: icon }} />
                          <div className="grow-card-content">
                            <h5>{title}</h5>
                            <p>{sub_title}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={btn_url}
                  className="ig-btn btn--lg btn--primary"
                >
                  {btn_name}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grow-img">
          <img src={`${imageBaseUrl}assets/file/images/frontend/${about_image}`} alt={about_image} />
        </div>
      </div>

      <div className="grow-shape-1">
        <img src="https://i.ibb.co/7WB6kXy/v1069-elements-004.png" alt="v1069-elements-004" />
      </div>

      <div className="grow-shape-2">
        <img src="https://i.ibb.co/PmCjn7P/v1069-elements-007.png" alt="v1069-elements-007" />
      </div>
    </section>
  );
};

export default AboutSection;
