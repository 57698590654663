import React, { useEffect, useState } from 'react';
import { fetchFeatureContent, fetchFeatureElements } from '../../services/apiService';

const FeatureSection = () => {
  const [featureContent, setFeatureContent] = useState([]);
  const [featureElements, setFeatureElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        console.log('Fetching feature content and elements...');
        const content = await fetchFeatureContent();
        const elements = await fetchFeatureElements();

        console.log('Feature content:', content);
        console.log('Feature elements:', elements);

        setFeatureContent(content); // Assuming content is an array
        setFeatureElements(elements); // Assuming elements is an array
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    console.log('Loading data...');
    
  }

  if (error) {
    console.error('Error encountered:', error);
  }

  if (!featureContent.length || !featureElements.length) {
    console.warn('No data available');
  }

  // Extract the first item from featureContent array
  const [firstContent] = featureContent;
  const {
    section_value: {
      heading = '',
      description = '',
      sub_heading = ''
    } = {}
  } = firstContent || {};

  console.log('Rendering feature section with content:', firstContent);
  console.log('Rendering feature section with elements:', featureElements);

  return (
    <section id="features" className="pt-100 pb-100">
      <div className="container">
        <div className="section-header">
          <div className="section-header-left">
            <span className="sub-title">{sub_heading}</span>
            <h3 className="section-title">{heading}</h3>
          </div>
          <div className="section-header-right">
            <p className="title-description">{description}</p>
          </div>
        </div>

        <div className="feature-items">
          {featureElements.map((element) => {
            const {
              section_value: {
                title = '',
                description = '',
                feature_icon = '',
                btn_name = "",
                btn_url = ""
              } = {}
            } = element;

            console.log(`Rendering feature element ${element.id}:`, element);

            return (
              <div className="feature-item" key={element.id}>
                <div className="feature-item-left">
                <span className="feature-icon" dangerouslySetInnerHTML={{ __html: feature_icon }} />
                  <h4 className="feature-title">{title}</h4>
                </div>
                <div className="feature-item-desc">
                  <p>{description}</p>
                </div>
                <div className="feature-item-right">
                <a href={btn_url} className="ig-btn btn--primary-outline btn--sm btn--capsule align-items-center" >
                    {btn_name} <i className="fa-solid fa-arrow-right-long ps-3"></i>
                </a>
                </div>
                <div className="modal fade" id={`section-${element.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog nafiz modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        {description}
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
