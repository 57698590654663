import React, { useEffect, useState } from 'react';
import { FAQSectionContent, FAQSectionElements } from '../../services/apiService';
import 'bootstrap/dist/css/bootstrap.min.css';

const FAQSection = () => {
  const [featureContent, setFeatureContent] = useState([]);
  const [featureElements, setFeatureElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const content = await FAQSectionContent();
        const elements = await FAQSectionElements();
        setFeatureContent(content);
        setFeatureElements(elements);
      } catch (error) {
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  const [firstContent] = featureContent;
  const {
    section_value: {
      sub_heading = '',
      heading = '',
      message_icon = '',
      message_title = '',
      message_description = '',
      btn_left_name = '',
      btn_left_url = '',
      btn_right_name = '',
      btn_right_url = '',
    } = {}
  } = firstContent || {};

  return (
    <section className="faq pt-100 pb-100" id="faq">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5">
            <div className="section-header section-header-two align-items-start text-start">
              <span className="sub-title">FAQ</span>
              <h3 className="section-title">{heading}</h3>
              <p className="title-description">{sub_heading}</p>
            </div>

            <div className="faq-left-content">
              <div className="faq-card mb-4">
                <div className="faq-card-title">
                  <span className="" dangerouslySetInnerHTML={{ __html: message_icon }} />
                  <h4>{message_title}</h4>
                </div>
                <p>{message_description}</p>
              </div>
              <div className="mt-5 d-flex align-items-center flex-wrap gap-4">
                <a target="_blank" rel="noopener noreferrer" href={btn_left_url} className="ig-btn btn--lg btn--primary">
                  {btn_left_name}
                </a>
                <a target="_blank" rel="noopener noreferrer" href={btn_right_url} className="ig-btn btn--lg btn--primary-outline">
                  {btn_right_name}
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="custom-accordion">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {featureElements.map((faq, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`flush-heading${index}`}>
                      <button
                        className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => handleQuestionClick(index)}
                      >
                        {faq.section_value.question}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {faq.section_value.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
