import React, { useState } from 'react';
import { uploadFile } from '../../services/apiService';
import FolderTree from './FolderTree'; // Import the recursive folder component

const UploadFileModal = ({ showModal, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [progress, setProgress] = useState(0);

  const handleFileUpload = async (file, folder, setProgress) => {
    if (!file || !folder) {
      alert('File and folder are required.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folder);

    try {
      await uploadFile(formData, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      });
      alert('File uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file.');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleFileUpload(selectedFile, selectedFolder, setProgress);
    handleClose();
  };

  return (
    <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" aria-labelledby="uploadFileModalLabel" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="uploadFileModalLabel">Upload File</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="file" className="form-label">Select File</label>
                <input type="file" className="form-control" id="file" name="file" onChange={(e) => setSelectedFile(e.target.files[0])} required />
                <input type="hidden" className="form-control" id="folder_id" name="folder_id"/>
              </div>
              <div className="mb-3">
                <label htmlFor="folder" className="form-label">Select Folder</label>
                {/* <FolderTree folders={folders} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} /> */}
              </div>
              {progress > 0 && (
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
              <button type="submit" className="btn btn-primary">Upload</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadFileModal;
