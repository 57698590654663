// MainLayout.js
import React, { useEffect, useState } from 'react';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import { Link, useNavigate } from 'react-router-dom';

import { alllanguages , getuser} from './services/apiService';


export const MainLayout = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

// import TopBar from './TopBar';
// import Sidebar from './Sidebar';
// Example data


// AuthLayout.js

export const AuthLayout = ({ children }) => {
    return (
        <div className="auth-container">
            {children}
        </div>
    );
};
