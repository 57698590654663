import React, { useEffect, useState } from 'react';
import { SharedHostingPlanes } from '../../services/apiCloudService';
import { convertCurrency, getuser, hostpricedata, checkdomainusename } from '../../services/apiService';

// "3_months_discount": "text",
//             "6_months_discount": "text",
//             "12_months_discount": "text",
//             "24_months_discount": "text",
//             "36_months_discount": "text",
//             "setupfee1_months": "text",
//             "setupfee3_months": "text",
//             "setupfee6_months": "text",
//             "setupfee12_months": "text",
//             "setupfee24_months": "text",
//             "setupfee36_months": "text",

const CreateHosting = () => {

    const [userdat, setUser] = useState([]);
    const token = localStorage.getItem('token');
    const [planData, setPlanData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [hostingcost, sethostingcost] = useState(0);
    const [months3discount, set3_months_discount] = useState(0);
    const [months6discount, set6_months_discount] = useState(0);
    const [months12discount, set12_months_discount] = useState(0);
    const [months24discount, set24_months_discount] = useState(0);
    const [months36discount, set36_months_discount] = useState(0);

    const [setupfee1_months, setsetupfee1_months] = useState(0);
    const [productsetupcost, setproductsetupcost] = useState(0);
    const [setupfee3_months, setsetupfee3_months] = useState(0);
    const [setupfee6_months, setsetupfee6_months] = useState(0);
    const [setupfee12_months, setsetupfee12_months] = useState(0);
    const [setupfee24_months, setsetupfee24_months] = useState(0);
    const [setupfee36_months, setsetupfee36_months] = useState(0);

    const [months1price, setmonths1price] = useState(0);
    const [months3price, setmonths3price] = useState(0);
    const [months6price, setmonths6price] = useState(0);
    const [months12price, setmonths12price] = useState(0);
    const [months24price, setmonths24price] = useState(0);
    const [months36price, setmonths36price] = useState(0);


    const [formValues, setFormValues] = useState({
        domain: '',
        username: '',
        password: '',
        retypePassword: '',
        billingType: '',
        productCost: 0,
        productSetupFee: 0,
        productDiscountAmount: 0,
        productSubtotalAmount: 0,
        productTaxAmount: 0,
        productTotalAmount: 0,
        productTotalNextBillDate: '',
        productTotalNextBillAmount: 0,
        todayBill: 0
    });

    const [availability, setAvailability] = useState({
        domain: '',
        username: ''
    });
    const [errors, setErrors] = useState({
        domain: '',
        username: ''
    });
    const [isFormValid, setIsFormValid] = useState(false);

    const [strengthMessage, setStrengthMessage] = useState('');
    const [matchMessage, setMatchMessage] = useState('');
    const [productName, setProductName] = useState([]);
    const [productCost, setProductCost] = useState(0);
    const [setupFee, setSetupFee] = useState(0);
    const [billType, setBillType] = useState([]);
    const [billCost, setBillCost] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [couponVisible, setCouponVisible] = useState(false);
    const [discountCouponPercent, setDiscountCouponPercent] = useState(0);
    const [discountCouponAmount, setDiscountCouponAmount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [totalBill, setTotalBill] = useState(0);
    const [nextBillDate, setNextBillDate] = useState('0');
    const [nextBillAmount, setNextBillAmount] = useState(0);
    const [todayBill, setTodayBill] = useState(0);

    const toggleCouponInput = () => {
        setCouponVisible(!couponVisible);
    };

    const updateTotalCost = async (planId, currencyCode, billingtype, discount_coupon) => {

        const response = await hostpricedata(planId, currencyCode, billingtype, discount_coupon);

        console.log('DATA API :', response);

        const product_namedata = response.plane_name;
        const product_costdata = response.plane_amount;
        const product_setup_feedata = response.product_setup_fee;
        let product_bill_typedata = '';

        if (response.plane_blling_type == 1) {
            product_bill_typedata = "Monthly";
        } else if (response.plane_blling_type == 3) {
            product_bill_typedata = "Quarterly";
        } else if (response.plane_blling_type == 6) {
            product_bill_typedata = "Semi-Annually";
        } else if (response.plane_blling_type == 12) {
            product_bill_typedata = "Annually";
        } else if (response.plane_blling_type == 24) {
            product_bill_typedata = "Biennially";
        } else if (response.plane_blling_type == 36) {
            product_bill_typedata = "Triennially";
        }

        const billingTypeDATA = response.plane_blling_type;

        console.log('BILLING TYPE:', product_bill_typedata);
        
        const product_bill_costdata = response.total_bill_amount;
        const product_discount_psdata = response.Discount_Percentage;
        const product_discount_amountdata = response.discount_amount;
        const discount_coupondata = response.discount_coupon;
        const product_discount_coupon_psdata = response.discount_coupon_Percentage;
        const product_discount_coupon_amountdata = response.discount_coupon_Amount;
        const product_subtotal_amountdata = response.subtotal;
        const product_tax_Percentagedata = response.tax_Percentage;
        const product_tax_amountdata = response.tax_amount;
        const product_total_amountdata = response.total_amount;
        const product_total_next_bill_datedata = response.next_billing_date;
        const product_total_next_bill_amountdata = response.next_billing_amount;
        const today_billdata = response.total_amount;

        
        setFormValues(prevValues => ({
            ...prevValues,
            billingType: billingTypeDATA,
            product_name: product_namedata || '',
            product_cost: (product_costdata || 0).toFixed(2),
            product_setup_fee: (product_setup_feedata || 0).toFixed(2),
            product_bill_type: product_bill_typedata,
            product_bill_cost: (product_bill_costdata || 0).toFixed(2),
            product_discount_ps: product_discount_psdata || 0,
            product_discount_amount: (product_discount_amountdata || 0).toFixed(2),
            discount_coupon: discount_coupondata || '',
            product_discount_coupon_ps: product_discount_coupon_psdata || 0,
            product_discount_coupon_amount: (product_discount_coupon_amountdata || 0).toFixed(2),
            product_subtotal_amount: (product_subtotal_amountdata || 0).toFixed(2),
            product_tax_Percentage: product_tax_Percentagedata || 0,
            product_tax_amount: (product_tax_amountdata || 0).toFixed(2),
            product_total_amount: (product_total_amountdata || 0).toFixed(2),
            product_total_next_bill_date: product_total_next_bill_datedata || '',
            product_total_next_bill_amount: (product_total_next_bill_amountdata || 0).toFixed(2),
            today_bill: (product_total_amountdata || 0).toFixed(2),
        }));
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                console.log('Fetching feature content and elements...');
                const response = await SharedHostingPlanes();
                const userdata = await getuser(token);
                console.log('API Response:', response); // Log the response

                setUser(userdata);

                if (response.data.plans && Array.isArray(response.data.plans)) {
                    setPlanData(response.data.plans); // Extract the array

                    // Initialize an array to hold the promises for currency conversion
                    const currencyConversionPromises = [];

                    response.data.plans.forEach(async (plan) => {
                        if (plan.section_value) {


                            set3_months_discount(plan.section_value['3_months_discount']);
                            set6_months_discount(plan.section_value['6_months_discount']);
                            set12_months_discount(plan.section_value['12_months_discount']);
                            set24_months_discount(plan.section_value['24_months_discount']);
                            set36_months_discount(plan.section_value['36_months_discount']);

                            setProductName(plan.section_value['plan_name']);
                            setBillType('Monthly');

                            setsetupfee1_months(plan.section_value['setupfee1_months']);
                            setsetupfee3_months(plan.section_value['setupfee3_months']);
                            setsetupfee6_months(plan.section_value['setupfee6_months']);
                            setsetupfee12_months(plan.section_value['setupfee12_months']);
                            setsetupfee24_months(plan.section_value['setupfee24_months']);
                            setsetupfee36_months(plan.section_value['setupfee36_months']);
                            const productsetupprice = await convertCurrency(plan.section_value['setupfee1_months'], userdat.currencyCode);

                            const convertedPrice = await convertCurrency(plan.section_value['price'], userdat.currencyCode);

                            //updateTotalCost(plan.id, userdat.currencyCode);


                            // Calculate prices after discount
                            // const month3price = convertedPrice * (1 - months3discount / 100);
                            // const total3MonthPrice = month3price * 3;
                            // const month6price = convertedPrice * (1 - months6discount / 100);
                            // const total6MonthPrice = month6price * 6;
                            // const month12price = convertedPrice * (1 - months12discount / 100);
                            // const total12MonthPrice = month12price * 12;
                            // const month24price = convertedPrice * (1 - months24discount / 100);
                            // const total24MonthPrice = month24price * 24;
                            // const month36price = convertedPrice * (1 - months36discount / 100);
                            // const total36MonthPrice = month36price * 36;

                            // Log the results to the console
                            // setmonths1price(convertedPrice);
                            // console.log('Price per month:', months1price);
                            // setmonths3price(month3price);
                            // console.log('Price for 3 months:', total3MonthPrice);
                            // setmonths6price(month6price);
                            // console.log('Price for 6 months:', total6MonthPrice);
                            // setmonths12price(month12price);
                            // console.log('Price for 12 months:', total12MonthPrice);
                            // setmonths24price(month24price);
                            // console.log('Price for 24 months:', total24MonthPrice);
                            // setmonths36price(month36price);
                            // console.log('Price for 36 months:', total36MonthPrice);
                        }
                    });

                    await Promise.all(currencyConversionPromises);

                } else {
                    console.error('API response is not in the expected format:', response);
                    setError('Failed to load data');
                }
            } catch (error) {
                console.error('Failed to load data:', error);
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [token, userdat.currencyCode]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handlebilltypeChange = async (e) => {
        const newBillingType = e.target.value;
        const discount_coupon = formValues.discount_coupon;
    
        // Update the formValues state with the new billing type first
        setFormValues(prevValues => ({
            ...prevValues,
            billingType: newBillingType
        }));
    
        // Then call updateTotalCost with the updated billing type
        updateTotalCost(formValues.planeid, userdat.currencyCode, newBillingType, discount_coupon);
    };

    const updatediscount_coupon = async (e) => {
        const discount_coupon = formValues.discount_coupon;
        const newBillingType = formValues.billingType;
        setFormValues(prevValues => ({
            ...prevValues,
            discount_coupon: discount_coupon
        }));
        console.log('discount_coupon:', discount_coupon, newBillingType);
    
        // Then call updateTotalCost with the updated billing type
        updateTotalCost(formValues.planeid, userdat.currencyCode, newBillingType, discount_coupon);
    };

    const handlePlanChange = async (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const planId = selectedOption.value;
        const selectedPlanData = planData.find(plan => plan.id === parseInt(planId, 10));
    
        if (selectedPlanData) {
            setSelectedPlan(selectedPlanData.section_value);
            setLoading1(true);
            setTimeout(() => {
                setLoading1(false);
            }, 1000);
            
            try {
                const convertedPrice = await convertCurrency(selectedPlanData.section_value.price, userdat.currencyCode);
                
                // Update the planeid value in your form values
                setFormValues(prevValues => ({
                    ...prevValues,
                    planeid: planId
                }));
    
                // Calculate prices after discount
                const month3price = convertedPrice * (1 - months3discount / 100);
                const total3MonthPrice = month3price * 3;
                const month6price = convertedPrice * (1 - months6discount / 100);
                const total6MonthPrice = month6price * 6;
                const month12price = convertedPrice * (1 - months12discount / 100);
                const total12MonthPrice = month12price * 12;
                const month24price = convertedPrice * (1 - months24discount / 100);
                const total24MonthPrice = month24price * 24;
                const month36price = convertedPrice * (1 - months36discount / 100);
                const total36MonthPrice = month36price * 36;
    
                setProductCost(convertedPrice);
    
                // Log the results to the console
                setmonths1price(convertedPrice);
                console.log('Price per month:', convertedPrice);
                setmonths3price(month3price);
                console.log('Price for 3 months:', total3MonthPrice);
                setmonths6price(month6price);
                console.log('Price for 6 months:', total6MonthPrice);
                setmonths12price(month12price);
                console.log('Price for 12 months:', total12MonthPrice);
                setmonths24price(month24price);
                console.log('Price for 24 months:', total24MonthPrice);
                setmonths36price(month36price);
                console.log('Price for 36 months:', total36MonthPrice);
                updateTotalCost(selectedPlanData.id, userdat.currencyCode);
            } catch (error) {
                console.error('Error converting currency:', error);
            }
        }
    };

    const renderDesignationContainer = () => {
        if (!selectedPlan) return null;

        return (
            <ul className="list-unstyled tx-14 fw-500 mb-4">
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">{selectedPlan.websites}</span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600"><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-unmetered" className="plain-link">{selectedPlan.Disk_Space}</a></span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600"><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal-unmetered" className="plain-link">{selectedPlan.Data_Transfer}</a></span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">cPanel + Softaculous</span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">{selectedPlan.Email}</span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">{selectedPlan.Database}</span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">LiteSpeed Web Server</span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">No Free Backups</span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">Free SSL Certificate
                            <a href="javascript:void(0);" data-bs-toggle="tooltip" className="ms-1 op-4 all-ease-03 tx-primary" aria-label="All Our Shared Hosting Servers now come with a pre-installed SSL by Let's Encrypt. This means your site will automatically be flagged as 'SECURE' and will ensure all information sent over the site is fully encrypted." data-bs-original-title="All Our Shared Hosting Servers now come with a pre-installed SSL by Let's Encrypt. This means your site will automatically be flagged as 'SECURE' and will ensure all information sent over the site is fully encrypted.">
                                <i className="bi bi-info-circle-fill tx-14"></i>
                            </a>
                        </span>
                    </div>
                </li>
                <li className="list-item mb-3">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">99.9% Up Time</span>
                    </div>
                </li>
                <li className="list-item">
                    <div className="d-flex align-items-start">
                        <span className="me-2 tx-primary"><i className="bi bi-check-circle"></i></span>
                        <span className="flex-grow-1 fw-600">24 x 7 Technical Support</span>
                    </div>
                </li>
                <h2 style={{ marginTop: '10px' }} id="total_bill">{userdat.symbol} {productCost}
                    <span className="tx-14 tx-muted op-7">&nbsp; / month</span>
                </h2>
            </ul>
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedValues = { ...formValues, [name]: value };
        
        if (name === 'domain') {
            // Trim and sanitize domain
            let trimmedDomain = value.trim().replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').replace(/\/$/, '');
            let username = extractUsername(trimmedDomain);
    
            // Update state with new domain and username
            updatedValues.domain = trimmedDomain;
            updatedValues.username = username;
    
            // Check availability
            checkAvailability('domain', trimmedDomain);
            checkAvailability('username', username);
        }
    
        if (name === 'password') {
            checkPasswordStrength(value);
        }
    
        if (name === 'retypePassword') {
            checkPasswordMatch(formValues.password, value);
        }
    
        // Update form values state
        setFormValues(updatedValues);
    };

    const checkPasswordStrength = (password) => {
        let strengthLevel = 0;
        const strongColor = "#4CAF50";
        const mediumColor = "#FFC107";
        const weakColor = "#FF5722";

        if (password.length >= 8) {
            const containsLowerCase = /[a-z]/.test(password);
            const containsUpperCase = /[A-Z]/.test(password);
            const containsNumbers = /\d/.test(password);
            const containsSymbols = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

            const combinations = (containsLowerCase ? 1 : 0) + (containsUpperCase ? 1 : 0) +
                                 (containsNumbers ? 1 : 0) + (containsSymbols ? 1 : 0);

            if (password.length >= 12 && combinations >= 3) {
                strengthLevel = 2;
            } else if (password.length >= 8 && combinations >= 2) {
                strengthLevel = 1;
            } else {
                strengthLevel = 0;
            }
        }

        const strengthColors = [weakColor, mediumColor, strongColor];
        const strengthMessages = ["Weak", "Medium", "Strong"];

        setStrengthMessage(
            <span style={{ color: strengthColors[strengthLevel] }}>
                {strengthMessages[strengthLevel]}
            </span>
        );
    };

    const checkPasswordMatch = (password, confirmPassword) => {
        if (password === confirmPassword && password.length > 0) {
            setMatchMessage(<span style={{ color: 'green' }}>Passwords match</span>);
        } else {
            setMatchMessage(<span style={{ color: 'red' }}>Passwords do not match</span>);
        }
    };

    const extractUsername = (url) => {
        let match = url.match(/[^.]+\.[^.]+$/);
        if (match && match.length > 0) {
            return match[0].replace(/\./g, '');
        }
        return '';
    };

    const checkAvailability = async (type, value) => {
        const data = await checkdomainusename(type, value);
        console.log(data);
        setAvailability(prev => ({
            ...prev,
            [type]: data.available ? 'Available' : 'Unavailable'
        }));
        setErrors(prev => ({
            ...prev,
            [type]: data.available ? '' : `${type.charAt(0).toUpperCase() + type.slice(1)} not available`
        }));
        validateForm();
    };

    const validateForm = () => {
        let domainAvailable = availability.domain === 'Available';
        let usernameAvailable = availability.username === 'Available';

        setIsFormValid(domainAvailable && usernameAvailable);
    };


    const handleSubmit = (e) => {
        console.log('Form Values:', formValues);
        if (!isFormValid) {
            e.preventDefault();
            alert('Please choose an available domain and username.');
        }
    };

    return (
        <div className="container-fluid p-0">
            <div className="card">
                <div className="card-body">
                    <form id="contact_store" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                        <div className="form-wrapper">
                            <div className="form-wrapper-title">
                                <h6>Select a Package</h6>
                            </div>

                            <div className="form-item mb-4">
                                <label className="form-label" htmlFor="group_id">
                                    Choose a Package
                                </label>
                                <select className="form-select" name="group_id" id="group_id" required onChange={handlePlanChange}>
                                    <option value="" >Select One</option>
                                    {planData.length > 0 ? (
                                        planData.map(plan => (
                                            <option key={plan.id} value={plan.id}>
                                                {plan.section_value.plan_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>No plans available</option>
                                    )}
                                </select>
                            </div>

                            <div className="file-tab">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="single-tab-pane" role="tabpanel" aria-labelledby="single-tab" tabIndex="0">
                                        <input type="hidden" name="single_contact" />

                                        <div className="row">
                                            <div id="designation-container" className="col-lg-6 col-md-12 mb-4">
                                                {loading1 ? (
                                                    <div style={{ background: '#ededed', height: '100%', borderRadius: '7px', display: 'flex', flexWrap: 'wrap', flexDirection: 'column-reverse', justifyContent: 'center', alignContent: 'space-around' }}>
                                                        <h5>Loading...</h5>
                                                    </div>
                                                ) : (
                                                    <div style={{ background: '#ededed', height: '100%', borderRadius: '7px', display: 'flex', flexWrap: 'wrap', flexDirection: 'column-reverse', justifyContent: 'center', alignContent: 'space-around' }}>

                                                        {renderDesignationContainer()}
                                                        <h5 style={{ marginBottom: '20px', fontSize: '39px' }}>{selectedPlan ? 'Plan Details' : 'Select Plan..'}</h5>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-item col-lg-12 mb-3">
                                                    <label htmlFor="domain" className="form-label">Domain <sup className="text--danger">*</sup></label>
                                                    <input type="hidden"required value={formValues.planeid} className="form-control" id="planeid" name="planeid" placeholder="Enter planeid" onChange={handleChange}/>
                                                    <input type="text" required value={formValues.domain} className="form-control" id="domain" name="domain" placeholder="Enter Domain" onChange={handleChange} />
                                                    <div id="domainAvailability">
                                                        {availability.domain && <span className={`text-${availability.domain === 'Available' ? 'success' : 'danger'}`}>{availability.domain}</span>}
                                                    </div>
                                                    <div id="domainError" className="text-danger">
                                                        {errors.domain}
                                                    </div>
                                                </div>

                                                <div className="form-item col-lg-12 mb-3">
                                                    <label htmlFor="username" className="form-label">Username <sup className="text--danger">*</sup></label>
                                                    <input type="text" required value={formValues.username} className="form-control" id="username" name="username" placeholder="Enter username" onChange={handleChange} />
                                                    <div id="usernameAvailability">
                                                        {availability.username && <span className={`text-${availability.username === 'Available' ? 'success' : 'danger'}`}>{availability.username}</span>}
                                                    </div>
                                                    <div id="usernameError" className="text-danger">
                                                        {errors.username}
                                                    </div>
                                                </div>

                                                <div className="form-item col-lg-12 mb-3">
                                                    <label htmlFor="password" className="form-label">Password <sup className="text--danger">*</sup></label>
                                                    <input type="password" required value={formValues.password} className="form-control" id="password" name="password" placeholder="Enter password" onChange={handleChange} />
                                                    <div id="passwordStrength">{strengthMessage}</div>
                                                </div>

                                                <div className="form-item col-lg-12 mb-3">
                                                    <label htmlFor="retype_password" className="form-label">Re-type Password <sup className="text--danger">*</sup></label>
                                                    <input type="password" required value={formValues.retypePassword} className="form-control" id="retype_password" name="retypePassword" placeholder="Re-type password" onChange={handleChange} />
                                                    <div id="passwordMatch">{matchMessage}</div>
                                                </div>

                                                <div className="form-item col-lg-12 mb-3">
                                                    <label htmlFor="billing_type" className="form-label">Billing Type</label>
                                                    <select className="form-select" id="billing_type" name="billingType" value={formValues.billingType} onChange={handlebilltypeChange}>
                                                        <option value="1" disabled={!selectedPlan}>{(months1price || 0).toFixed(2)} / Monthly</option>
                                                        <option value="3" disabled={!selectedPlan}>{(months3price || 0).toFixed(2)} /M Quarterly</option>
                                                        <option value="6" disabled={!selectedPlan}>{(months6price || 0).toFixed(2)} /M Semi-Annually</option>
                                                        <option value="12" disabled={!selectedPlan}>{(months12price || 0).toFixed(2)} /M Annually</option>
                                                        <option value="24" disabled={!selectedPlan}>{(months24price || 0).toFixed(2)} /M Biennially</option>
                                                        <option value="36" disabled={!selectedPlan}>{(months36price || 0).toFixed(2)} /M Triennially</option>
                                                        {/* Add more options as needed */}
                                                    </select>
                                                </div>

                                                {/* Display order summary */}
                                                <div>
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <h6>Order Summary</h6>
                                                        <h6>Bill Amounts</h6>
                                                    </div>
                                                    <hr />
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <input type="text" id="product_name" name="product_name" value={productName} readOnly style={{ border: 'none' }} />
                                                        <input type="text" id="product_cost" name="product_cost" value={`${userdat.symbol} ${productCost}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <hr />
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_setup">Setup Fee</p>
                                                        <input type="text" id="product_setup_fee" name="product_setup_fee" value={`${userdat.symbol} ${formValues.product_setup_fee}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <input type="text" id="product_bill_type" name="product_bill_type" value={formValues.product_bill_type} readOnly style={{ border: 'none' }} />
                                                        <input type="text" id="product_bill_cost" name="product_bill_cost" value={`${userdat.symbol} ${formValues.product_bill_cost}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_discount">Discount</p>
                                                        <input type="text" id="product_discount_ps" name="product_discount_ps" value={`${formValues.product_discount_ps}%`}  readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <p id="product_discount">Discount Amount</p>
                                                            <a id="apply_coupon_button" onClick={toggleCouponInput} style={{ background: '#260055', color: 'white', padding: '0px 9px', borderRadius: '10px', cursor: 'pointer' }}>Apply Coupon</a>
                                                        </div>
                                                        <input type="text" id="product_discount_amount" name="product_discount_amount" value={`${userdat.symbol} ${formValues.product_discount_amount}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <hr />
                                                    {couponVisible && (
                                                        <>
                                                            <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <label htmlFor="discount_coupon">Discount Coupon</label>
                                                                <input type="text" id="discount_coupon"  name="discount_coupon" value={formValues.discount_coupon} className="form-control" placeholder="Enter Coupon Code" style={{width: '250px'}} onChange={handleChange}/>
                                                                <a id="submit_coupon_button" onClick={updatediscount_coupon} style={{ background: '#260055', color: 'white', padding: '2px 9px', borderRadius: '10px', cursor: 'pointer' }}>Submit Coupon</a>
                                                            </div>
                                                            <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <p id="product_subtotal">Discount Coupon %</p>
                                                                <input type="text" id="product_discount_coupon_ps" name="product_discount_coupon_ps" value={formValues.product_discount_coupon_ps} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                            </div>
                                                            <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <p id="product_subtotal">Discount Coupon Amount</p>
                                                                <input type="text" id="product_discount_coupon_amount" name="product_discount_coupon_amount" value={`${userdat.symbol} ${formValues.product_discount_coupon_amount}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_subtotal">Sub Total</p>
                                                        <input type="text" id="product_subtotal_amount" name="product_subtotal_amount" value={`${userdat.symbol} ${formValues.product_subtotal_amount}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_tax">Tax {formValues.product_tax_Percentage}%</p>
                                                        {/* <input type="text" id="product_tax_Percentage" name="product_tax_Percentage" value={formValues.product_tax_Percentage} readOnly style={{ border: 'none', textAlign: 'right' }} /> */}
                                                        <input type="text" id="product_tax_amount" name="product_tax_amount" value={formValues.product_tax_amount} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <hr />
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_total">Total Bill</p>
                                                        <input type="text" id="product_total_amount" name="product_total_amount" value={`${userdat.symbol} ${formValues.product_total_amount}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <hr />
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_total">Next Bill Date</p>
                                                        <input type="text" id="product_total_next_bill_date" name="product_total_next_bill_date" value={formValues.product_total_next_bill_date} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p id="product_total">Next Bill Amount</p>
                                                        <input type="text" id="product_total_next_bill_amount" name="product_total_next_bill_amount" value={`${userdat.symbol} ${formValues.product_total_next_bill_amount}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <hr />
                                                    <div className="form-item col-lg-12 mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <h6>Today Bill</h6>
                                                        <input type="text" id="today_bill" name="today_bill" value={`${userdat.symbol} ${formValues.today_bill}`} readOnly style={{ border: 'none', textAlign: 'right' }} />
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-item col-lg-12" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateHosting;
