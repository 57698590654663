import React, { useEffect, useState } from 'react';
import { ClientsContent, ClientsCounte } from '../../../services/apiService';

const Clients = () => {
  const [featureContent, setFeatureContent] = useState([]);
  const [totalUsers, setTotalUsers] = useState('');
  const [totalSubscribedUsers, setTotalSubscribedUsers] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const content = await ClientsContent();
        const count = await ClientsCounte();

        console.log('Client counts:', count);
        setFeatureContent(content);
        setTotalUsers(count.totalUsers);
        setTotalSubscribedUsers(count.totalSubscribedUsers);
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);
  
  // Destructuring
  const [firstContent] = featureContent;
  const { section_value: { heading = '', sub_heading = '' } = {} } = firstContent || {};

  return (
    <div className="clients py-5">
      <div className="container">
        <div className="row gx-0 gy-5 align-items-center">
          <div className="col-xl-5 col-lg-4">
            <div className="client-left">
              <p>{sub_heading}</p>
              <h4 className="section-title">{heading}</h4>
            </div>
          </div>
          <div className="col-xl-7 col-lg-8">
            <div className="client-right">
              <div className="client-right-item">
                <h3>{totalUsers}+</h3>
                <p>Worldwide Customers</p>
              </div>
              <div className="client-right-item">
                <h3>{totalSubscribedUsers}+</h3>
                <p>Worldwide Subscribed Customers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
