import axios from 'axios';
import io from 'socket.io-client'; // Import Socket.IO client
import { useSocket } from '../context/SocketContext';




const appmode = process.env.REACT_APP_MODE; 
const app_url = appmode === 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL;
console.log('app_url :', app_url);
const API_URL = `${app_url}api/`;
console.log('API_URL :', API_URL);


//console.log('Socket:', socket); // Check what `socket` is


export const getImageBaseUrl = () => {
  const appmode = process.env.REACT_APP_MODE; 
  console.log('api url', appmode === 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL);
  return appmode === 'LIVE' ? process.env.REACT_APP_IMAGE_BASE_LIVEURL : process.env.REACT_APP_IMAGE_BASE_LOCALURL; 
  
};


export const fetchFolderContentssocket = async (socket, page, folderName, loginToken) => {
  //onst socket = useSocket(); // Access the socket from context
  console.log('socket RUN DATA :', socket);
  return new Promise((resolve, reject) => {
    // Emit event to fetch folder contents
    
    // Emit the event with the correct data
    socket.emit('fetch-folder-contents', {
      page: page,
      login_tockin: loginToken,
      foldername: folderName
    });

    // Listen for the response from the server
    socket.on('folder-contents-response', (data) => {
      if (data.error) {
        console.error('Error fetching folder contents:', data.error);
        reject(data.error);
      } else {
        resolve(data.items);
      }
    });

    // Handle errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
      reject(error);
    });
  });
};


export const fetchPaginatedItemsSocket = (socket, pageNumber, loginToken, foldername) => {
  console.log('Socket connected 22:', socket);
  return new Promise((resolve, reject) => {
    if (!socket || typeof socket.emit !== 'function') {
      return reject(new Error('Socket is not properly initialized'));
    }

    if (!socket.connected) {
      return reject(new Error('Socket is not connected'));
    }

    // Emit the event with the correct data
    socket.emit('fetch-paginated-items', {
      page: pageNumber,
      login_tockin: loginToken,
      foldername: ""
    });

    // Listen for the response from the server
    const onResponse = (data) => {
      if (data.error) {
        console.error('Error fetching paginated items:', data.error);
        reject(data.error);
      } else {
        console.log('FILE DATA GET:', data);  // Logging received data
        resolve(data.items);
      }

      // Cleanup the listener after response
      socket.off('paginated-items-response', onResponse);
    };

    socket.on('paginated-items-response', onResponse);

    // Handle socket errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
      reject(error);
    });
  });
};





export const fetchFeatureContent = async () => {
  try {
    const response = await axios.get(`http://localhost:3001/api/frontendSection/frontend-sections/feature.fixed_content`);
    console.log('Feature Content all:', response.data); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const fetchFeatureElements = async () => {
  try {
    const response = await axios.get(`http://localhost:3001/api/frontendSection/frontend-sections/feature.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const ChooseUsContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/cta.fixed_content`);
    console.log('url :', API_URL); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const ChooseUsElements = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/cta.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const processContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/process.fixed_content`);
    console.log('url :', API_URL); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const processElement = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/process.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const AboutSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/about.fixed_content`);
    console.log('url :', API_URL); // Log the content to check

    // Assuming response.data is an array, you can iterate and log the `id` for each item.
    if (Array.isArray(response.data)) {
      response.data.forEach(item => {
        console.log('ID:', item.id); // Print the ID
      });
    } else {
      // If response.data is not an array, just log the `id`
      console.log('ID:', response.data.id);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching feature content:', error);
    throw error;
  }
};

export const AboutSectionElement = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/about.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const ClientsContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/clients.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const HeroSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/banner.fixed_content`);
    console.log('banner.fixed_content :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const HeroSectionsocialIconsContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/social_icon.element_content`);
    console.log('icon data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const OverviewContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/over_view.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const TeamSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/mumbers.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const TeamSectionElements = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/mumbers.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const FAQSectionContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/faq.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const FAQSectionElements = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/faq.element_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const PricingPlansContent = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/frontend-sections/pricing_plan.fixed_content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const PricingPlansElement = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/pricing-plans`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};
export const HeroSectionUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const ClientsCounte = async () => {
  try {
    const response = await axios.get(`${API_URL}user-stats`);
    console.log('user count :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const fetchSettings = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/general-settings`);
    console.log('general_settings data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const general_settings = async () => {
  try {
    const response = await axios.get(`${API_URL}frontendSection/general-settings`);
    console.log('general_settings data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};


export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}login`, {
      email,
      password,
    });

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const UserForgotPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}forgotpassword`, {
      email,
    });

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const UserVerify = async (email, otp) => {
  try {
    const response = await axios.post(`${API_URL}verifyOtp`, {
      email,
      otp
    });

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};  

export const updatepassword = async (email, newPassword) => {
  try {
    const response = await axios.post(`${API_URL}update-password`, {
      email,
      newPassword
    });

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const registerUser = async (name, email, password) => {
  try {
    const response = await axios.post(`${API_URL}registerUser`, {
      name,
      email,
      password
    });

    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const getuser = async (login_tockin) => {
  try {
    const response = await axios.post(`${API_URL}getUser`, {
      login_tockin
    });
    console.log('user data :', response.data);
    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const alllanguages = async () => {
  try {
    const response = await axios.get(`${API_URL}languages`);
    console.log('languages data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const getcurrencies = async () => {
  try {
    const response = await axios.get(`${API_URL}currency/currencies`);
    console.log('currencies data :', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature elements:', error);
    throw error;
  }
};

export const updateUserCurrency = async (login_tockin, currencyCode,symbol) => {
  try {
    const response = await axios.post(`${API_URL}users/currency`, {
      login_tockin,
      currencyCode,
      symbol
    });
    return response.data; // Assuming the API returns a token or user data
  } catch (error) {
    throw error.response ? error.response.data : new Error('Login failed');
  }
};

export const convertCurrency = async (priceInUSD, currencyCode) => {
  try {
    const response = await axios.post(`${API_URL}currency/getConvterCurrencies`, {
      priceInUSD,
      currencyCode,
    });
    return response.data.convertedPrice;
  } catch (error) {
    console.error('Error converting currency:', error);
    return priceInUSD; // Return original price if conversion fails
  }
};

export const hostpricedata = async (planId, currencyCode, billingtype, coupon) => {
  try {
    const response = await axios.post(`${API_URL}cloud/hostpricedata`, {
      planId, currencyCode, billingtype,coupon
    });
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return planId; // Return original price if conversion fails   
  }
};

export const checkdomainusename = async (type, value) => {
  try {
    const response = await axios.post(`${API_URL}cloud/check-availability`, {
      type, value
    });
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

const apiClient = axios.create({
  baseURL: 'http://localhost:30001/api', // Adjust the base URL as needed
  headers: {
    'Content-Type': 'application/json',
  },
});

export const uploadFile = (formData, onUploadProgress) => {
  return apiClient.post('/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const getFolderList = async (login_tockin) => {
  try {
    const response = await axios.post(`${API_URL}files/list-folders`, {
      login_tockin
    });
    console.log('FOLDERDATA:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};
export const CreateFolder = async (login_tockin, foldername, folderid, root) => {
  try {
    const response = await axios.post(`${API_URL}files/create-folder`, {
      login_tockin,
      foldername,
      folderid,
      root
    });
    console.log('CreateFolder:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error converting currency:', error);
    return error; // Return original price if conversion fails   checkdomainusename
  }
};

export const fetchFolderContents = async (folderName, loginToken) => {
  try {
      const response = await fetch(`${API_URL}files/cloud-storage`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${loginToken}`, // If your API requires authorization
          },
          body: JSON.stringify({
              foldername: folderName,
              login_tockin: loginToken
          }),
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.items;
  } catch (error) {
      console.error('Error fetching folder contents:', error);
      throw error;
  }
};

export const fetchPaginatedItems = async (pageNumber, loginToken) => {
  try {
      const response = await fetch(`${API_URL}files/cloud-storage`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${loginToken}`,
          },
          body: JSON.stringify({
              page: pageNumber,
              login_tockin: loginToken
          }),
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('FILE DATA GET :', data);  // Note that we log `data` here instead of `response.data`
      return data.items;
  } catch (error) {
      console.error('Error fetching page:', error);
      throw error;
  }
};