import React, { useState, useEffect } from 'react';
import TopBar from './user/partials/topbar';
import Sidebar from './user/partials/sidebar';
import { useNavigate } from 'react-router-dom';
import { alllanguages, getuser, general_settings, getcurrencies } from './services/apiService';



const profileImage = '/path/to/profile-image.jpg';

export const UserLayout = ({ children }) => {
    const token = localStorage.getItem('token');
    const [languages, setLanguages] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [settings, setSettings] = useState([]);
    const [userdat, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }

        const fetchLanguages = async () => {
            try {
                const response = await alllanguages();
                const userdata = await getuser(token);
                const Setting = await general_settings();
                const gecurrencies = await getcurrencies();
                setLanguages(response);
                setUser(userdata);
                setSettings(Setting[0] || {});
                setCurrencies(gecurrencies);
            } catch (err) {
                if (err.message === 'User not found') {
                    localStorage.clear();
                    navigate('/login');
                } else {
                    setError(err.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchLanguages();
    }, [token, navigate]);

    if (!token) return null; // Early return moved here
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const toggleSidebar = () => {
        setIsSidebarMinimized(!isSidebarMinimized);
    };

    console.log('user dashbore Setting :', settings);

    return (
        <>
            <div className="content" id="main-content">
                <Sidebar settings={settings} userdat={userdat} isMinimized={isSidebarMinimized}/>
                <div className="overlay"></div>
                <div id="mainContent" className={`main_content added ${isSidebarMinimized ? 'minimized' : ''}`}>
                    <TopBar
                        currencies={currencies}
                        languages={languages}
                        userdat={userdat}
                        profileImage={profileImage}
                        settings={settings}
                        toggleSidebar={toggleSidebar}
                    />
                    <div className="dashboard_container">
                        <div className="inner-content">
                            {children}
                        </div>
                    </div>
                    <footer>
                        <div className="footer-content">
                            <p>© {new Date().getFullYear()} Your Company</p>
                            <div className="footer-right">
                                <span>App-Version: 1.0.0</span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
};
