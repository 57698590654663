import React, { useEffect, useState } from 'react';

import { processContent, processElement, getImageBaseUrl } from '../../services/apiService';

// Define the ProcessSection component
const ProcessSection = () => {
    // Helper function to get values from the section_value

    const [featureContent, setFeatureContent] = useState([]);
    const [featureElements, setFeatureElements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl


    useEffect(() => {
        setImageBaseUrl(getImageBaseUrl());
        const loadData = async () => {
        try {
            console.log('Fetching feature content and elements...');
            const content = await processContent();
            const elements = await processElement();

            console.log('Feature content:', content);
            console.log('Feature elements:', elements);

            setFeatureContent(content); // Assuming content is an array
            setFeatureElements(elements); // Assuming elements is an array
        } catch (error) {
            console.error('Failed to load data:', error);
            setError('Failed to load data');
        } finally {
            setLoading(false);
        }
        };

        loadData();
    }, []);

    if (loading) {
        console.log('Loading data...');
        
    }

    if (error) {
        console.error('Error encountered:', error);
    }

    if (!featureContent.length || !featureElements.length) {
        console.warn('No data available');
    }

    // Extract the first item from featureContent array
    const [firstContent] = featureContent;
    const {
        section_value: {
        title = '',
        heading = '',
        description = '',
        sub_heading = '',
        sub_title = '',
        btn_name = '',
        btn_url =''
        } = {}
    } = firstContent || {};

    console.log('Rendering feature section with content:', firstContent);
    console.log('Rendering feature section with elements:', featureElements);

    

    return (
        <section className="pt-100 pb-100">
            <div className="container">
                <div className="section-header">
                    <div className="section-header-left">
                        <span className="sub-title">
                            {sub_title}
                        </span>

                        <h3 className="section-title">
                            {title}
                        </h3>
                    </div>

                    <div className="section-header-right">
                        <p className="title-description">
                            {description}
                        </p>
                    </div>
                </div>

                <div className="process-step">
                    {featureElements.map((element, index) => {
                        const {
                            section_value: {
                                heading = '',
                                sub_heading = '',
                                card_image = ''
                            } = {}
                        } = element;

                        return (
                            <div className="process-step-item" key={index}>
                                <div className="step-left">
                                    <div className="step-img">
                                        <img src={`${imageBaseUrl}assets/file/images/frontend/${card_image}`} alt={heading} />
                                    </div>
                                </div>

                                <div className="step-middle">
                                    <div className="step-indicator">
                                        <span></span>
                                    </div>
                                </div>

                                <div className="step-right">
                                    <div className="step-content">
                                        <h4>{heading}</h4>
                                        <p>{sub_heading}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        </section>
    );
};

export default ProcessSection;
