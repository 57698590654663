import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Mock data for demonstration purposes
const mockContacts = [
    {
        id: 1,
        domain_name: 'example.com',
        product_name: 'Basic Plan',
        account_share: 'Shared',
        status: 1,
        product_total_next_bill_date: '2024-08-15',
        today_bill: '50.00',
        username: 'user1',
        password: 'pass1',
        cpanel_host: 'cpanel.example.com',
        whatsapp_contact: '1234567890',
        sms_contact: '0987654321',
        email_contact: 'user@example.com',
        attributes: {
            attribute1: 'value1',
            attribute2: 'value2'
        },
        created_at: '2024-07-01T12:00:00Z',
        updated_at: '2024-08-01T12:00:00Z'
    },
    // Add more mock data as needed
];

const HistrySharedHost = ({ title, errors, data = { search: '' } }) => {
    const [search, setSearch] = useState(data.search || '');
    const [contactData, setContactData] = useState(mockContacts);
    const navigate = useNavigate();

    // Fetch contacts based on search
    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get('/user/contact/search', { params: { search } });
                setContactData(response.data);
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        if (search) {
            fetchContacts();
        } else {
            setContactData(mockContacts);
        }
    }, [search]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        // Fetch contacts based on the updated search term
        axios.get('/user/contact/search', { params: { search } })
            .then(response => {
                setContactData(response.data);
            })
            .catch(error => {
                console.error('Error searching contacts:', error);
            });
    };

    const handleReset = () => {
        setSearch('');
        navigate('/user/contact/index');
    };

    const handleDeleteContact = (username) => {
        // Handle delete contact
        axios.delete(`/user/delete.cpanel.account/${username}`)
            .then(response => {
                // Optionally refresh the contact list after deletion
                setContactData(prevContacts => prevContacts.filter(contact => contact.username !== username));
            })
            .catch(error => {
                console.error('Error deleting contact:', error);
            });
    };

    const handleDownloadInvoice = (id) => {
        // Handle download invoice
        window.location.href = `/user/invoices/download/${id}`;
    };

    return (
        <section>
            <div className="card mb-4">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>

                <div className="card-filter">
                    <form onSubmit={handleSearchSubmit}>
                        <div className="filter-form">
                            <div className="filter-item">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Type Domain"
                                    className="form-control"
                                    id="search"
                                    value={search}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div className="filter-action">
                                <button className="i-btn info--btn btn--md" type="submit">
                                    <i className="fas fa-search"></i> Search
                                </button>
                                <button
                                    className="i-btn danger--btn btn--md text-white"
                                    type="button"
                                    onClick={handleReset}
                                >
                                    <i className="las la-sync"></i> Reset
                                </button>

                                <div className="statusUpdateBtn d-none">
                                    <a
                                        className="i-btn success--btn btn--md bulkAction"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Bulk Actions"
                                    >
                                        <i className="fas fa-gear"></i> Action
                                    </a>
                                </div>
                                <a
                                    href="/user/shared_hosting_create"
                                    className="i-btn primary--btn btn--md text-white"
                                    title="Add New Contact"
                                >
                                    <i className="fa fa-plus"></i> Add New
                                </a>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="card-body px-0">
                    <div className="responsive-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="form-check-input mt-0 me-2 checkAll"
                                                type="checkbox"
                                                value=""
                                                aria-label="Checkbox for following text input"
                                            /> 
                                            <span>Sl No.</span>
                                        </div>
                                    </th>
                                    <th>Domain</th>
                                    <th>Package</th>
                                    <th>Sharing</th>
                                    <th>Status</th>
                                    <th>Next Bill</th>
                                    <th>Bill Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {contactData.length ? contactData.map((contact, index) => (
                                    <tr key={contact.id}>
                                        <td className="d-none d-sm-flex align-items-center">
                                            <input
                                                className="form-check-input mt-0 me-2"
                                                type="checkbox"
                                                name="contactUid"
                                                value={contact.username}
                                                aria-label="Checkbox for following text input"
                                            />
                                            {index + 1}
                                        </td>
                                        <td data-label="Domain">
                                            <a href={`https://www.${contact.domain_name}`} className="badge badge--primary p-2" target="_blank">
                                                www.{contact.domain_name}
                                            </a>
                                        </td>
                                        <td data-label="Package">
                                            <a href={`/user/contact/group/${contact.product_id}`} className="badge badge--primary p-2">
                                                {contact.product_name}
                                            </a>
                                        </td>
                                        <td data-label="Sharing" style={{ display: 'flex' }}>
                                            {contact.account_share || <span className="badge badge--info">N/A</span>}
                                            <a
                                                className="i-btn info--btn btn--sm"
                                                href="#"
                                                title="Share Account"
                                                data-bs-toggle="modal"
                                                data-bs-target="#inviteUserModal"
                                                style={{ marginLeft: '5px' }}
                                            >
                                                <i className="fa fa-plus"></i>
                                            </a>
                                        </td>
                                        <td data-label="Status">
                                            {contact.status === 0 && <span className="badge badge--success">Pending</span>}
                                            {contact.status === 1 && <span className="badge badge--success">Run</span>}
                                            {contact.status === 2 && <span className="badge badge--danger">Suspend</span>}
                                            {contact.status === 3 && <span className="badge badge--info" style={{ background: 'black', color: 'white' }}>Terminate</span>}
                                            {contact.status === 4 && <span className="badge badge--info" style={{ background: 'black', color: 'white' }}>Delete</span>}
                                        </td>
                                        <td data-label="Next Bill">
                                            {contact.product_total_next_bill_date || <span className="badge badge--info">N/A</span>}
                                        </td>
                                        <td data-label="Bill Amount">
                                            {contact.today_bill ? `` : <span className="badge badge--info">N/A</span>}
                                        </td>
                                        <td data-label="Action">
                                            <div className="d-flex align-items-center justify-content-md-start justify-content-end gap-3">
                                                {contact.status === 1 && (
                                                    <a
                                                        className="i-btn info--btn btn--sm"
                                                        href={`https://${contact.cpanel_host}:2083/login/?user=${contact.username}&pass=${contact.password}`}
                                                        title="Login cPanel"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-sign-in"></i>
                                                    </a>
                                                )}
                                                <a
                                                    className="i-btn danger--btn btn--sm"
                                                    href="#"
                                                    onClick={() => handleDownloadInvoice(contact.id)}
                                                    style={{ backgroundColor: '#299cdb' }}
                                                    title="Download Invoice"
                                                >
                                                    <i className="fa fa-download"></i>
                                                </a>
                                                <a
                                                    className="i-btn danger--btn btn--sm"
                                                    href="#"
                                                    onClick={() => handleDeleteContact(contact.username)}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteContact"
                                                    title="Delete Contact"
                                                >
                                                    <i className="las la-trash"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td className="text-muted text-center" colSpan="8">
                                            <i className="fa fa-box-open"></i> No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HistrySharedHost;
