// src/config/colorConfig.js
export function getValidColor(color) {
  if (typeof color !== 'string') {
    return null;
  }
  
  const hexColor = color.startsWith('#') ? color : `#${color}`;
  return /^#[a-fA-F0-9]{6}$/.test(hexColor) ? hexColor : null;
}

export function getColors(queryParams) {
  const primaryColor = getValidColor(queryParams.primaryColor) || '#260055'; // Default primary color
  const secondaryColor = getValidColor(queryParams.secondaryColor) || '#8105f0'; // Default secondary color
  return { primaryColor, secondaryColor };
}
