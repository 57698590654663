import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import io from 'socket.io-client'; // Import Socket.IO client
import CreateFolderModal from './CreateFolderModal';
import UploadFileModal from './UploadFileModal';
import { useSocket } from '../../context/SocketContext';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchFolderContents, fetchPaginatedItems, fetchFolderContentssocket, fetchPaginatedItemsSocket, CreateFolder } from '../../services/apiService';

const CloudStorage = ({
    title,
    paginatedItems = [],
    totalPages: initialTotalPages = 1,
    currentPage = 1,
    searchQuery,
    onSearch,
    onReset,
    onCreateFolder,
    onUploadFile,
    onDeleteFolder,
    onDeleteFile,
    humanFileSize,
}) => {
    const navigate = useNavigate();
    const socket = useSocket(); // Get the socket from context     setCurrentFolderId(folderId);
    const [CurrentFolderId, setCurrentFolderId] = useState([]);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [search, setSearch] = useState(searchQuery || '');
    const [paginatedItemsState, setPaginatedItemsState] = useState(paginatedItems);
    const [currentPath, setCurrentPath] = useState(['/']); // Initialize with root path
    const [totalPages, setTotalPages] = useState(initialTotalPages);
    const [totalrecords, setTotalRecords] = useState([]);
    const loginToken = localStorage.getItem('token');


    


    useEffect(() => {
        // Fetch initial data if loginToken is available
        if (loginToken) {
            handlePageChange(currentPage);
        }
    }, [loginToken, currentPage]);

    const handleCreateFolder = async (folderName, folderid, folderroot) => {
        try {
            // Call API to create a folder
            console.log(`Creating folder: ${folderName}`);
            console.log('loginToken:', loginToken);
            console.log('path:', folderid);
            let subfolder;
            let root;
            let name;
            if(folderroot == "Home"){
                subfolder = "";
                root = "";
            }else{
                subfolder = folderName;
                root = folderroot.slice(7); // Keep the remaining part after "Home/"
            }

            if(subfolder){
                name = folderroot.slice(7); // Keep the remaining part after "Home/";
            }else{
                name = folderName;
            }
            const create = await CreateFolder(loginToken,folderName, folderid, root)
            if(create){
                setShowCreateFolderModal(false);
                // Display success toast message
                toast.success(create.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }else{
                toast.error(create.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
            }
            
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };

    const handleFileUpload = async (file, folder, setProgress) => {
        try {
            // Handle file upload logic here, including progress update
            console.log(`Uploading file: ${file.name} to folder: ${folder}`);
            if (onUploadFile) {
                await onUploadFile(file, folder);
            }
            setProgress(100); // Example progress completion
            setShowUploadFileModal(false);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (onSearch) {
            onSearch(search);
        }
    };

    const handleReset = () => {
        setSearch('');
        if (onReset) {
            onReset();
        }
    };

    const onFolderClick = async (item) => {
        try {
            if (loginToken) {
                const newItems = await fetchFolderContentssocket(socket, 1, item.fileName, loginToken);
                console.log('FILES newItems data check:', newItems);
                // Construct the new URL by appending the folder ID
                const newUrl = `${window.location.pathname}?folderId=${encodeURIComponent(item.id)}`;

                // Update the URL in the browser without reloading the page
                window.history.pushState({ path: newUrl }, '', newUrl);
    
                // Update paginated items
                setPaginatedItemsState(newItems.items || []); // Ensure it's an array

                setCurrentFolderId(item.id);
                
                // Update the currentPath state
                setCurrentPath((prevPath) => {
                    const updatedPath = [...prevPath, item.fileName];
                    console.log('Updated Path:', updatedPath);
                    return updatedPath;
                });
            }
        } catch (error) {
            console.error('Error fetching folder contents:', error);
        }
    };

    const handlePageChange = async (pageNumber) => {
        try {
            if (loginToken) {
                // Emit the event to fetch paginated items
                socket.emit('fetch-paginated-items', {
                    page: pageNumber,
                    login_tockin: loginToken,
                    foldername: "", // Specify the folder name if needed
                });
    
                // Define the response handler
                const onResponse = (data) => {
                    if (data.error) {
                        console.error('Error fetching paginated items:', data.error);
                        return;
                    }
    
                    console.log('FILES data check:', data); // Log the received data
                    setPaginatedItemsState(data.items.items); // Update state with received items
                    setTotalPages(data.items.totalPages); // Update totalPages from response
                    setTotalRecords(data.items.totalPages);
                    // Cleanup the listener after response
                    socket.off('paginated-items-response', onResponse);
                };
    
                // Listen for the response from the server
                socket.on('paginated-items-response', onResponse);
            }
        } catch (error) {
            console.error('Error fetching page:', error);
        }
    };

    const handleBreadCrumbClick = async (index) => {
        const newPath = currentPath.slice(0, index + 1);
        setCurrentPath(newPath);

        if (loginToken) {
            const folderName = newPath[newPath.length - 1];

            try {
                const items = await fetchPaginatedItemsSocket(socket, 1, loginToken, folderName);
                console.log('FILES data check:', items);
                setPaginatedItemsState(items.items);
                setTotalPages(items.totalPages);
            } catch (error) {
                console.error('Error fetching paginated items:', error);
            }
        }
    };

    const isImage = (filePath) => {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
        return imageExtensions.some((ext) => filePath.toLowerCase().endsWith(ext));
    };

    const isVideo = (filePath) => {
        const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv'];
        return videoExtensions.some((ext) => filePath.toLowerCase().endsWith(ext));
    };

    const onView = (item) => {
        console.log('Viewing file:', item);
        // Add logic to view file, e.g., open in modal or new tab
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const humanFileSize1 = (size) => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    };

    return (
        <section>
            <div className="card mb-4">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>

                <div className="card-filter">
                    <form onSubmit={handleSearch}>
                        <div className="filter-form">
                            <div className="filter-item">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search"
                                    placeholder="Type file name & folder name"
                                    className="form-control"
                                    id="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>

                            <div className="filter-action">
                                <Button variant="info" type="submit">
                                    <i className="fas fa-search"></i> Search
                                </Button>
                                <Button variant="danger" onClick={handleReset}>
                                    <i className="las la-sync"></i> Reset
                                </Button>

                                <Button variant="primary" onClick={() => setShowCreateFolderModal(true)}>
                                    <i className="fa fa-folder"></i> Create Folder
                                </Button>
                                <Button variant="primary" onClick={() => setShowUploadFileModal(true)}>
                                    <i className="fa fa-upload"></i> Upload File
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>

                {/* Breadcrumb navigation */}
                <div className="breadcrumb" style={{ padding: '0px 0px 0px 23px' }}>
                    <span>
                        <a href="#" onClick={() => handleBreadCrumbClick(0)} style={{ fontSize: '18px' }}>
                            Home
                        </a>
                    </span>
                    {currentPath.slice(1).map((part, index) => (
                        <span key={index}>
                            {' / '}
                            <a href="#" onClick={() => handleBreadCrumbClick(index + 1)} style={{ fontSize: '18px' }}>
                                {part}
                            </a>
                        </span>
                    ))}
                </div>

                <CreateFolderModal
                    showModal={showCreateFolderModal}
                    handleClose={() => setShowCreateFolderModal(false)}
                    handleCreateFolder={handleCreateFolder}
                    path={currentPath.slice(1)} // Ensure this is an array of strings
                    folderId={CurrentFolderId}   // Pass the folderId to the modal
                />

                <UploadFileModal
                    showModal={showUploadFileModal}
                    handleClose={() => setShowUploadFileModal(false)}
                    onUploadFile={handleFileUpload}
                />

                <div className="card-body px-0">
                    <div className="responsive-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Path</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Size</th>
                                    <th>Last Modified</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItemsState.length === 0 ? (
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: 'center' }}> {/* Adjust the colspan based on your table structure */}
                                            No Data Available
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedItemsState.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {item.type === 'folder' ? (
                                                    <a href="#" onClick={() => onFolderClick(item)}>
                                                        {item.fileName} ({item.fileName})
                                                    </a>
                                                ) : (
                                                    item.fileName
                                                )}
                                            </td>
                                            <td>{item.fileName}</td>
                                            <td>{item.type}</td>
                                            <td>{item.size}</td>
                                            <td>{formatDate(item.updatedAt)}</td>
                                            <td style={{ display: 'flex', padding: '8px' }}>
                                                {item.type === 'folder' ? (
                                                    <>
                                                        <Button variant="primary" onClick={() => onFolderClick(item)} style={{ height: '38px' }}>
                                                            Open
                                                        </Button>
                                                        <Button variant="danger" onClick={() => onDeleteFolder(item)} style={{ marginLeft: '10px' }}>
                                                            Delete
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {(isImage(item.path) || isVideo(item.path)) && (
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => onView(item)}
                                                                style={{ marginRight: '10px', height: '38px' }}
                                                            >
                                                                View
                                                            </Button>
                                                        )}
                                                        <a href={item.url} download={item.name} className="btn btn-primary" style={{ height: '38px' }}>
                                                            Download
                                                        </a>
                                                        <Button variant="danger" onClick={() => onDeleteFile(item)} style={{ marginLeft: '10px' }}>
                                                            Delete
                                                        </Button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>

                        </Table>

                        <div
                            className="pagination"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end', // Align items to the right
                                marginRight: '32px',
                                marginTop: '23px',
                                gap: '10px'
                            }}
                            >
                            {[...Array(totalPages)].map((_, index) => (
                                <Button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                                >
                                {index + 1}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="m-3"></div>
                </div>
                {/* Include ToastContainer */}
                <ToastContainer
                    position="top-right" // Ensure this is set to a string
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </section>
    );
};

export default CloudStorage;
