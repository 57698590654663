import React, { useState, useEffect } from 'react';

const CreateFolderModal = ({ showModal, handleClose, handleCreateFolder, path, folderId }) => {
  const [folderName, setFolderName] = useState('');
  const [folderRoot, setFolderRoot] = useState('');
  const [currentFolderId, setCurrentFolderId] = useState('');

  // Generate the path string for display
  const pathString = Array.isArray(path) && path.length > 0
    ? ['Home', ...path].join(' / ')
    : 'Home';

  // Update folderRoot and currentFolderId whenever path or folderId changes
  useEffect(() => {
    setFolderRoot(pathString);
    setCurrentFolderId(folderId);
  }, [pathString, folderId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitting form with folderName:', folderName, 'and folderRoot:', folderRoot);

    // Call the function to create the folder with folderName and folderId
    handleCreateFolder(folderName, currentFolderId, folderRoot); // Pass the currentFolderId instead of folderRoot

    // Reset folder name after creation
    setFolderName('');
    handleClose();
  };

  return (
    <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" aria-labelledby="createFolderModalLabel" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createFolderModalLabel">Create Folder</h5>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="folder_name" className="form-label">Folder Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="folder_name"
                  name="folder_name"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  required
                />
                <label htmlFor="folder_root" className="form-label">Current Path:</label>
                <input
                  type="text"
                  className="form-control"
                  id="folder_root"
                  name="folder_root"
                  value={folderRoot}
                  readOnly
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
              <button type="submit" className="btn btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
