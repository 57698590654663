import React from 'react';
import { Helmet } from 'react-helmet';

const DynamicSEO = ({ title, description, keywords, favicon, ogTitle, ogDescription, ogImage, twitterCard }) => {
  return (
    <Helmet>
      {/* Dynamic Title */}
      <title>{title}</title>

      {/* Dynamic Meta Description */}
      <meta name="description" content={description} />

      {/* Dynamic Meta Keywords */}
      <meta name="keywords" content={keywords} />

      {/* Dynamic Favicon */}
      <link rel="icon" href={favicon} type="image/x-icon" />

      {/* Open Graph Meta Tags (for sharing on Facebook, etc.) */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={ogDescription} />
      <meta name="twitter:image" content={ogImage} />
    </Helmet>
  );
};

export default DynamicSEO;
