// src/components/About/About.js
import React, { useEffect, useState } from 'react';
import HeroSection from '../common/HeroSection/HeroSection'; // Correct path based on file structure
import Clients from '../common/Clients/Clients';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import AboutSection from '../common/AboutSection';
import ProcessSection from '../common/ProcessSection';
import ChooseUsSection from '../common/ChooseUsSection'; // Adjust the import path as needed  
import OverviewSection from '../common/OverviewSection';
import TeamSection from '../common/TeamSection';
import FAQSection from '../common/FAQSection';
import PricingPlans from '../common/PricingPlans';
import FeatureSection from '../common/FeatureSection';

import { fetchFeatureContent, fetchFeatureElements, getImageBaseUrl } from '../../services/apiService';

const Features = () => {
  const [featureContent, setFeatureContent] = useState([]);
  const [featureElements, setFeatureElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageBaseUrl, setImageBaseUrl] = useState(''); // State for imageBaseUrl

  useEffect(() => {
    setImageBaseUrl(getImageBaseUrl());
    const loadData = async () => {
      try {
        console.log('Fetching feature content and elements...');
        const content = await fetchFeatureContent();
        const elements = await fetchFeatureElements();

        console.log('Feature content:', content);
        console.log('Feature elements:', elements);

        setFeatureContent(content); // Assuming content is an array
        setFeatureElements(elements); // Assuming elements is an array
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    console.log('Loading data...');
    
  }

  if (error) {
    console.error('Error encountered:', error);
  }

  if (!featureContent.length || !featureElements.length) {
    console.warn('No data available');
  }

  // Extract the first item from featureContent array
  const [firstContent] = featureContent;
  const {
    section_value: {
      feature_breadcrumb_image = ''
    } = {}
  } = firstContent || {};

  console.log('Rendering feature section with content:', firstContent);
  console.log('Rendering feature section with elements:', featureElements);

  return (
    <div>
      <section className="breadcrumb-banner">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2>Pricing</h2>
            <div className="d-inline-block mt-4">
              <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Pricing
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="breadcrumb-bg">
          <img src={`${imageBaseUrl}assets/file/images/frontend/${feature_breadcrumb_image}`} alt="Background" />
        </div>
      </section>
      <PricingPlans />
      <OverviewSection />
      <FAQSection />
      
    </div>
  );
};

export default Features;
