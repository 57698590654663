// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getColors } from './config/colorConfig'; // Correct import path
import Home from './components/screens/Home';
import About from './components/screens/About';
import Features from './components/screens/Features';
import Pricing from './components/screens/Pricing';
import Faq from './components/screens/Faq';
import Login from './components/screens/Login';
import ForgotPassword from './components/screens/Forgot-Password';
import UpdatePassword from './components/screens/Update-Password';
import Verify from './components/screens/Verify';
import Register from './components/screens/Register';
import Dashboard  from './user/Dashbord';
import ProtectedRoute from './ProtectedRoute';
import CreateHosting from './user/Cloud/CreateHosting';
import CreateVps from './user/Cloud/CreateVps';
import HistrySharedHost from './user/Cloud/HistrySharedHost';
import CloudStorage from './user/Cloud/CloudStorage';
import SeodataAdd from './user/seo/seodataadd';
import './assets/theme/global/css/line-awesome.min.css';
import './assets/theme/global/css/font_bootstrap-icons.css';
import './assets/theme/frontend/css/all.min.css';
import './assets/theme/frontend/css/default.css';
import './assets/theme/frontend/css/style.css';
import './assets/theme/frontend/css/media.css';

import { MainLayout } from './MainLayout';
import { UserLayout } from './UserLayout';
import { AuthLayout } from './MainLayout';


import { getAuthToken, clearAuthToken } from './utils/AuthenticatedApp';

import { fetchSettings } from './services/apiService'; // Import your API function

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(!!getAuthToken());

  useEffect(() => {
    let logoutTimer;

    const resetLogoutTimer = () => {
      if (logoutTimer) clearTimeout(logoutTimer);

      logoutTimer = setTimeout(() => {
        clearAuthToken();
        setIsLoggedIn(false);
        alert('You have been logged out due to inactivity.');
      }, 3600000); // 1 hour of inactivity
    };

    // Event listeners for user interactions
    window.addEventListener('mousemove', resetLogoutTimer);
    window.addEventListener('keydown', resetLogoutTimer);
    window.addEventListener('scroll', resetLogoutTimer);
    window.addEventListener('click', resetLogoutTimer);

    // Initializing the timer on mount
    resetLogoutTimer();

    // Cleanup event listeners on unmount
    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetLogoutTimer);
      window.removeEventListener('keydown', resetLogoutTimer);
      window.removeEventListener('scroll', resetLogoutTimer);
      window.removeEventListener('click', resetLogoutTimer);
    };
  }, []);

  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSettings = async () => {
      const settingsData = await fetchSettings();
      setSettings(settingsData[0] || {});
      setLoading(false);
    };

    getSettings();
  }, []);

  return (

    <Router>
      <Routes>
        <Route path="/" element={<MainLayout><Home /></MainLayout>} />
        <Route path="/about" element={<MainLayout><About /></MainLayout>} />
        <Route path="/features" element={<MainLayout><Features /></MainLayout>} />
        <Route path="/pricing" element={<MainLayout><Pricing /></MainLayout>} />
        <Route path="/faq" element={<MainLayout><Faq /></MainLayout>} />

        <Route path="/login" element={<AuthLayout settings={settings}><Login /></AuthLayout>} />
        <Route path="/forgot-password" element={<AuthLayout settings={settings}><ForgotPassword /></AuthLayout>} />
        <Route path="/verify" element={<AuthLayout settings={settings}><Verify /></AuthLayout>} />
        <Route path="/UpdatePassword" element={<AuthLayout settings={settings}><UpdatePassword /></AuthLayout>} />
        <Route path="/register" element={<AuthLayout settings={settings}><Register /></AuthLayout>} />
        <Route path="/dashboard" element={<ProtectedRoute> <UserLayout><Dashboard /></UserLayout></ProtectedRoute>} />
        <Route path="/shared-hosting-create" element={<ProtectedRoute> <UserLayout><CreateHosting /></UserLayout></ProtectedRoute>} />
        <Route path="/vps_server" element={<ProtectedRoute> <UserLayout><CreateVps /></UserLayout></ProtectedRoute>} />
        <Route path="/shared-hosting-history" element={<ProtectedRoute> <UserLayout><HistrySharedHost /></UserLayout></ProtectedRoute>} />
        <Route path="/CloudStorage" element={<ProtectedRoute> <UserLayout><CloudStorage /></UserLayout></ProtectedRoute>} />
        <Route path="/seo_data_add" element={<ProtectedRoute> <UserLayout><SeodataAdd /></UserLayout></ProtectedRoute>} />
        
        {/* Add other routes here */}
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
