import React from 'react';
import './BannerIcon.css'; // Create a CSS file for the styles

const BannerIcon = () => {
  return (
    <div className="banner-icon sms-icon">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve">
        <g>
          <linearGradient id="a" x1="2.01" x2="17.99" y1="14" y2="14" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#16b0e2"></stop>
            <stop offset="1" stopColor="#6e5af0"></stop>
          </linearGradient>
          <linearGradient xlinkHref="#a" id="b" x1="8.999" x2="11.001" y1="12.984" y2="12.984"></linearGradient>
          <linearGradient xlinkHref="#a" id="c" x1="12.496" x2="14.499" y1="12.984" y2="12.984"></linearGradient>
          <linearGradient xlinkHref="#a" id="d" x1="5.501" x2="7.503" y1="12.984" y2="12.984"></linearGradient>
          <linearGradient xlinkHref="#a" id="e" x1="6.05" x2="21.99" y1="8.754" y2="8.754"></linearGradient>
          <g data-name="1">
            <path fill="black" d="M17.99 10.774v4a6.106 6.106 0 0 1-.04.75c-.23 2.7-1.82 4.04-4.75 4.04h-.4a.805.805 0 0 0-.64.32l-1.2 1.6a1.132 1.132 0 0 1-1.92 0l-1.2-1.6a.923.923 0 0 0-.64-.32h-.4c-3.19 0-4.79-.79-4.79-4.79v-4c0-2.93 1.35-4.52 4.04-4.75a6.076 6.076 0 0 1 .75-.04h6.4q4.785 0 4.79 4.79z" opacity="1" data-original="url(#a)"></path>
            <path fill="url(#b)" d="M10.004 13.984H10a1 1 0 1 1 .005 0z" opacity="1" data-original="url(#b)"></path>
            <path fill="url(#c)" d="M13.501 13.984h-.005a1 1 0 1 1 .005 0z" opacity="1" data-original="url(#c)"></path>
            <path fill="url(#d)" d="M6.506 13.984h-.005a1 1 0 1 1 .005 0z" opacity="1" data-original="url(#d)"></path>
            <path fill="url(#e)" d="M21.99 6.774v4q0 4.41-4.04 4.75a6.106 6.106 0 0 0 .04-.75v-4q0-4.785-4.79-4.79H6.8a6.076 6.076 0 0 0-.75.04c.23-2.69 1.82-4.04 4.75-4.04h6.4q4.785 0 4.79 4.79z" opacity="1" data-original="url(#e)"></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default BannerIcon;
