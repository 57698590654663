// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { general_settings, getImageBaseUrl } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { getColors } from '../../config/colorConfig';
import '../../assets/theme/global/css/line-awesome.min.css';
import '../../assets/theme/global/css/font_bootstrap-icons.css';
import '../../assets/theme/frontend/css/all.min.css';
import '../../assets/theme/frontend/css/default.css';
import '../../assets/theme/frontend/css/style.css';
import '../../assets/theme/frontend/css/media.css';
import Preloader from '../../components/Preloader'; // Adjust the path as needed

const queryParams = new URLSearchParams(window.location.search);
const { primaryColor, secondaryColor } = getColors({
  primaryColor: queryParams.get('primary_color'),
  secondaryColor: queryParams.get('secondary_color')
});

const Header = () => {
  const [featureContent, setFeatureContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [imageBaseUrl, setImageBaseUrl] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust this duration as needed
    const loadData = async () => {
      try {
        const content = await general_settings();
        setFeatureContent(content[0] || {}); // Assuming content is an array
        setImageBaseUrl(getImageBaseUrl());
      } catch (error) {
        console.error('Failed to load data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false); // Ensure loading is set to false after data is loaded
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (error) return <p>{error}</p>;
  if (loading) return <Preloader />;
  if (!featureContent || !featureContent.site_logo) return <p>No data available</p>;

  return (
    <header className={`header ravi ${isSticky ? 'sticky' : ''}`}>
      <div className="container-fluid fluid-wrapper">
        <div className="header-wrap">
          <Link to="/" className="site-logo">
            <img src={`${imageBaseUrl}assets/file/images/logoIcon/${featureContent.site_logo}`} alt="Site Logo" />
          </Link>

          <div className="nav-menu-wrapper">
            <div className="d-flex align-items-center justify-content-between w-100 d-lg-none">
              <div className="mobile-site-logo">
                <img src={`${imageBaseUrl}assets/file/images/logoIcon/${featureContent.site_logo}`} alt={featureContent.site_logo} />
              </div>

              <div className="close-sidebar">
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>

            <nav className="nav-menu">
              <ul>
                <li className="nav-menu-item"><Link to="/">Home</Link></li>
                <li className="nav-menu-item"><Link to="/about">About</Link></li>
                <li className="nav-menu-item"><Link to="/features">Features</Link></li>
                <li className="nav-menu-item"><Link to="/pricing">Pricing</Link></li>
                <li className="nav-menu-item"><Link to="/faq">Faq's</Link></li>
              </ul>
            </nav>
            <div className="d-lg-none w-100">
              <Link to="/login" className="ig-btn btn--primary btn--md btn--capsule btn-nowrap w-100" style={{ background: `linear-gradient(-75deg, ${primaryColor} 31%, ${secondaryColor} 98%)` }}>
                Sign In
              </Link>
            </div>
          </div>

          <div className="d-lg-flex d-none align-items-center gap-3 head-action">
            <Link to="/login" className="ig-btn btn--primary11 btn--md btn--capsule btn-nowrap" style={{ background: `linear-gradient(-75deg, ${primaryColor} 31%, ${secondaryColor} 98%)` }}>
              Sign In
            </Link>
          </div>

          <div className="d-lg-none">
            <span className="bars"><i className="fa-solid fa-bars"></i></span>
          </div>
        </div>
      </div>

      <div className="main-nav-overlay d-lg-none"></div>
    </header>
  );
};

export default Header;
